import React from "react"
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import "./all.scss"

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (

        <footer className="footer has-background-primary has-text-white-ter mt-5">
          <div className="content has-text-centered">
            <h1 className={`footer-title`}>Kevin Kent's Homepage</h1>
          </div>
          <div className="content has-text-left has-background-primary has-text-white-ter">
            <div className="container has-background-primary has-text-white-ter">
              <div style={{ maxWidth: '100vw' }} className="columns is-centered">
                <div className="column is-4">
                  <h4 className="has-text-left  menu-label">
                    General
                  </h4>
                  <ul className={`menu-list`}>
                    <li>
                      <AniLink fade className={`navbar-item`} to="/">Home</AniLink>
                    </li>
                  </ul>

                  <h4 className="has-text-left menu-label">
                    Projects
                  </h4>

                  <ul className={`menu-list`}>
                    <li>
                      <AniLink className={`navbar-item`} to="https://next-news.kevinkent1505.vercel.app/">NextJS News Aggregator</AniLink>
                    </li>
                    <li>
                      <AniLink className={`navbar-item`} to="https://github.com/kevinkent1505/TrussCalculator-CIVL1160">CIVL1160 Truss Calculator</AniLink>
                    </li>
                    <li>
                      <AniLink  className={`navbar-item`} to="/projects/comp4461-project1">COMP4461 Project 1</AniLink>
                    </li>
                    <li>
                      <AniLink className={`navbar-item`} to="/projects/comp4461-project2">COMP4461 Project 2</AniLink>
                    </li>
                    <li>
                      <AniLink className={`navbar-item`} to="/projects/comp4461-project3">COMP4461 Project 3</AniLink>
                    </li>


                  </ul>
                </div>


              </div>
            </div>
          </div>
        </footer>

    )
  }
}