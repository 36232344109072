import React from "react"
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import "./all.scss"

export default class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.hamburgerClickHandler = this.hamburgerClickHandler.bind(this);
    this.dropdownClickHandler = this.dropdownClickHandler.bind(this)
    this.state = {
      isActive: false,
      navbarActiveClass: " ",
      dropdownActive: false,
      dropdownClass: " "
    }
  }

  hamburgerClickHandler() {
    this.setState({ isActive: !this.state.isActive })
    this.state.isActive ? this.setState({ navbarActiveClass: "is-active" }) : this.setState({ navbarActiveClass: "" })
  }

  dropdownClickHandler() {
    this.setState({ dropdownActive: !this.state.dropdownActive })
    this.state.dropdownActive ? this.setState({ dropdownClass: "is-active" }) : this.setState({ dropdownClass: "" })
  }

  render() {
    return (
      <nav className={`navbar`} role="navigation" aria-label="main-navigation">
        <div className="container">
          <div className="navbar-brand">
            <AniLink className={`navbar-item`} to="/">Kevin Kent's Homepage</AniLink>
            <a role="button" className={`navbar-burger burger ${this.state.navbarActiveClass}`}
               onClick={this.hamburgerClickHandler} aria-label="menu" aria-expanded="false"
               data-target="navbarBasicExample">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div className={`navbar-menu ${this.state.navbarActiveClass}`}>
            <div className="navbar-start">

              <div className={`navbar-item has-dropdown is-hoverable`}>
                <a className={`navbar-link`}>
                  Projects
                </a>

                <div className={`navbar-dropdown`}>
                  <AniLink className={`navbar-item`} to="https://next-news.kevinkent1505.vercel.app/">NextJS News Aggregator</AniLink>
                  <AniLink className={`navbar-item`} to="https://github.com/kevinkent1505/TrussCalculator-CIVL1160">CIVL1160 Truss Calculator</AniLink>
                  <AniLink className={`navbar-item`} to="/projects/comp4461-project1">COMP4461 Project 1</AniLink>
                  <AniLink className={`navbar-item`} to="/projects/comp4461-project2">COMP4461 Project 2</AniLink>
                  <AniLink className={`navbar-item`} to="/projects/comp4461-project3">COMP4461 Project 3</AniLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}