import React from "react"
import Nav from "./nav"
import Footer from "./footer"
import {Helmet} from "react-helmet"


export default function Container({ children }) {
  return <div className="containers">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Kevin Kent's Homepage</title>
      <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600&display=swap" rel="stylesheet" />
      <link rel="icon" type="image/png" href={"/img/favicon.ico"} sizes="256x256" />
    </Helmet>
    <Nav />
    {children}
    <Footer />
  </div>
}